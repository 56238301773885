import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'
import { TwoColorIconDefinition } from 'design/components/Icons/types'

export const def: TwoColorIconDefinition = (
  colors = { base: 'currentColor', accent: 'currentColor' }
) => {
  // TODO: These colors were not in the theme!
  return {
    id: 'blankCardFront',
    name: 'Black Card Front',
    category: 'payment',
    path: (
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill={colors.base}
        />
        <path
          d="M8 28h18v5H8zM8 21h51v5H8zM8 10h10v8H8z"
          fill={colors.accent}
        />
      </g>
    ),
    viewBox: '0 0 66 46',
  }
}

export const IconCcBlankCardFront = makeIcon(
  def({ base: '#EFEFEF', accent: '#c2c2c2' })
)
export default IconCcBlankCardFront
